/* eslint-disable no-undefined */
'use client';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BookIcon from '@mui/icons-material/Book';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Box,
  Button, Divider, Drawer, IconButton, List,
  ListItemButton, ListItemIcon, ListItemText,
  Popover,
  Stack, Toolbar, Typography
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import Link from "next/link";
import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { onLogOut } from '@/lib/client/appEvents';
import { useTranslations } from 'next-intl'; // Assuming next-intl is used for i18n

export default function TopToolbar({ user }: { user: any }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const router = useRouter();
  const g = useTranslations('Global'); // Fetch translations
  const t = useTranslations('Components.Site.TopToolbar'); // Fetch translations

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;

  return (
    <>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label={t('menu')}
          onClick={() => setIsDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Image
          src="/iconTransparent.png"
          alt="DidThey?"
          width="40"
          height="40"
          style={{ filter: 'invert(1)' }}
          priority
        />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link href="/" passHref prefetch={false}>
            <Button color="inherit" size="large" sx={{ color: theme.palette.primary.contrastText }}>
              DidThey?
            </Button>
          </Link>
        </Typography>

        <Stack direction="row" spacing={2} alignItems="center" sx={{ mr: 3, display: { xs: 'none', sm: 'block' } }}>
          <Link href="/home" passHref prefetch={false}>
            <Button color="inherit" size="small" sx={{ color: theme.palette.primary.contrastText }}>
              {g('home')}
            </Button>
          </Link>
          <Link href="/home/profile" passHref prefetch={false}>
            <Button color="inherit" size="small" sx={{ color: theme.palette.primary.contrastText }}>
              {g('profile')}
            </Button>
          </Link>
          <Link href="/blog" passHref prefetch={false}>
            <Button color="inherit" size="small" sx={{ color: theme.palette.primary.contrastText }}>
              {g('blog')}
            </Button>
          </Link>
        </Stack>
        {user ? (
          <>
            <Button onClick={handleClick}>
              {user.picture ?
                <Avatar src={user.picture} alt={user.name} /> :
                <AccountCircleIcon sx={{ color: theme.palette.primary.contrastText }} />
              }
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box sx={{ p: 2, width: 300 }}>
                <Button onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
                  <Typography variant="h6">X</Typography>
                </Button>
                <Stack direction="column" spacing={2} alignItems="center">
                  <Typography variant="h6" component="div">
                    {t('greeting', { name: user.name })}
                  </Typography>
                  {user.picture ?
                    <Avatar src={user.picture} alt={user.name} sx={{ width: 80, height: 80 }}
                    /> :
                    <AccountCircleIcon sx={{ color: theme.palette.primary.contrastText }} />
                  }
                  <Typography variant="body1" component="div">
                    {user.email}
                  </Typography>
                  <Button color="inherit" size="small" variant="contained"
                    onClick={() => { setAnchorEl(null); router.push('/home/profile'); }}
                  >{t('editProfile')}</Button>
                  <Divider />
                  <Button color="inherit" size="small" variant="contained" startIcon={<LogoutIcon />}
                    onClick={() => {
                      setAnchorEl(null);
                      onLogOut();
                      router.push('/api/auth/logout');
                    }}
                  >{t('logOut')}</Button>
                </Stack>
              </Box>
            </Popover>
          </>
        ) : (
          <Link href="/api/auth/login" prefetch={false} passHref>
            <Button color="inherit" size="small" variant="contained">{t('logIn')}</Button>
          </Link>
        )}
      </Toolbar >
      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Stack direction="row" spacing={2} sx={{ p: 2 }} alignContent="center">
          <Image src="/icon.png" alt="DidThey?" width="40" height="40" />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            DidThey?
          </Typography>
        </Stack>
        <List sx={{ width: '250px' }}>
          <Link href="/" passHref prefetch={false}>
            <ListItemButton onClick={() => setIsDrawerOpen(false)}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={t('home')} />
            </ListItemButton>
          </Link>
          <Link href="/blog" passHref prefetch={false}>
            <ListItemButton onClick={() => setIsDrawerOpen(false)}>
              <ListItemIcon><BookIcon /></ListItemIcon>
              <ListItemText primary={t('blog')} />
            </ListItemButton>
          </Link>
          <Link href="/help" passHref prefetch={false}>
            <ListItemButton onClick={() => setIsDrawerOpen(false)}>
              <ListItemIcon><BookIcon /></ListItemIcon>
              <ListItemText primary={t('help')} />
            </ListItemButton>
          </Link>
          <Divider />
          {user ? (
            <>
              <Link href="/home/profile" passHref prefetch={false}>
                <ListItemButton onClick={() => setIsDrawerOpen(false)}>
                  <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                  <ListItemText primary={t('editProfile')} />
                </ListItemButton>
              </Link>
              <Link href="/api/auth/logout" passHref prefetch={false}>
                <ListItemButton onClick={() => {
                  onLogOut();
                  setIsDrawerOpen(false);
                }}>
                  <ListItemIcon><LogoutIcon /></ListItemIcon>
                  <ListItemText primary={t('logOut')} />
                </ListItemButton>
              </Link>
            </>
          ) : (
            <Link href="/api/auth/login" passHref prefetch={false}>
              <ListItemButton onClick={() => setIsDrawerOpen(false)}>
                <ListItemIcon><LoginIcon /></ListItemIcon>
                <ListItemText primary={t('logIn')} />
              </ListItemButton>
            </Link>
          )}
        </List>
      </Drawer>
    </>
  );
}