'use client'

/* eslint-disable no-restricted-imports */
import { getApi } from '@/lib/client/didTheyApi';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import {
  CachesProvider,
  DidTheyAdapters,
  DidTheyApiProvider
} from '@didthey/providers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { NextIntlClientProvider } from 'next-intl';
import { SnackbarProvider } from 'notistack';
import defaultTranslations from './IntlDefaultTranslations';

function GlobalProviders({
  locale,
  now,
  timeZone,
  messages,
  children,
}: {
  locale: string,
  now: Date,
  timeZone: string,
  messages: any,
  children: React.ReactNode
}) {

  return (
    <UserProvider>
      <NextIntlClientProvider messages={messages}
        defaultTranslationValues={defaultTranslations} locale={locale} now={now} timeZone={timeZone}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider maxSnack={3}>
            {children}
          </SnackbarProvider>
        </LocalizationProvider>
      </NextIntlClientProvider>
    </UserProvider>
  );
};

export default function Providers({
  didTheyApiUrl,
  jwtToken,
  locale,
  now,
  timeZone,
  messages,
  children,
}: {
  didTheyApiUrl: string | undefined,
  jwtToken: string | undefined,
  locale: string,
  now: Date,
  timeZone: string,
  messages: any,
  children: React.ReactNode
}) {

  return (
    <GlobalProviders locale={locale} now={now} timeZone={timeZone} messages={messages}>
      <DidTheyApiProvider didTheyApi={getApi(didTheyApiUrl, jwtToken)}>
        <CachesProvider>
          <DidTheyAdapters>
            {children}
          </DidTheyAdapters>
        </CachesProvider>
      </DidTheyApiProvider>
    </GlobalProviders>
  )
}
